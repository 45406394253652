$(document).ready(function ($) {

	var reasons = $('.reason:not(.reason-default)');
	if(reasons.length) {
		reasons.not('.reason-default').find('.reason-text').hide();
		var defaultReason = $('.reason-default');
		reasons.each(function(){
			$(this).on('mouseover', function() {
				if(typeof(mouseout) !== 'undefined') clearTimeout(mouseout);
				$('.reason-text').removeClass('active').hide();
				$(this).find('.reason-text').show().addClass('active');
			});
			$(this).on('mouseout', function() {
				$(this).find('.reason-text').removeClass('active');
				mouseout = setTimeout(function() {
					$(this).find('.reason-text').hide(0, function() {
						defaultReason.find('.reason-text').show().addClass('active');
					});
				}.bind($(this)), 150);
			});
		})
	}

	$('.eq').matchHeight();

	/**********************************************
	 RESIZE END / SCROLL END
	 **********************************************/
	var resizeEnd;
	$(window).on('resize', function () {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function () {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function () {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function () {
			$(window).trigger('scrollEnd');
		}, 100);
	});

});

function jQuerySelectorEscape(expression) {
	return expression.replace(/[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
}

// Zobrazení elementu ve viewportu
function inViewport(el) {
	var elH = $(el).height(),
			elTop = $(el).offset().top,
			scrollTop = $(window).scrollTop(),
			winH = $(window).height();
	fixedHeaderH = 0; // výška fixní hlavičky

	return ((elTop + elH) > (scrollTop + fixedHeaderH) && (scrollTop - elH) > (elTop - winH));
}
