 jQuery(function(){
	var body = $('body'),
		menuTrigger = $('.menu-trigger');

	menuTrigger.on('click', function(e) {
		e.preventDefault();
		body.toggleClass('menu-open');
	});

	/* lng switch */
	$('.lang-switch-trigger').on('click', function(e) {
		e.preventDefault();
		var langsList = $(this).closest('.lang-switch').find('.langs');
		if(langsList.length > 0) {
			langsList.toggleClass('langs-open');
		}
	});

	$(document).bind('click', function (e) {
		if (!$('.lang-switch').is(e.target) && $('.lang-switch').has(e.target).length === 0) {
			$('.langs').removeClass('langs-open');
		}
	});
 });